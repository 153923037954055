<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon
          name="notifications-outline"
          *ngIf="contentType === 'BANNER'"
        ></ion-icon>
        <ion-icon
          name="document-text-outline"
          *ngIf="contentType === 'HTML' || contentType === 'UNDEFINED'"
        ></ion-icon>
        <ion-icon name="open-outline" *ngIf="contentType === 'LINK'"></ion-icon>
        <ion-icon
          name="film-outline"
          *ngIf="contentType === 'VIDEO'"
        ></ion-icon>
        <ion-icon
          name="film-outline"
          *ngIf="contentType === 'TANGO'"
        ></ion-icon>
        <ion-icon
          name="rocket-outline"
          *ngIf="contentType === 'GOLIVE'"
        ></ion-icon>

        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this content.</p>
    </div>
    <form [formGroup]="form1" class="form-panel">
      <div class="form-panel-inner">
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>
        <ng-container *ngIf="editMode === 'edit'">
          <h2>{{ content?.name }}</h2>
          <p class="margin-bottom-24">
            {{ content?.ClassContainer?.name }} > {{
            content?.SubCategoryContainer?.name }} > {{
            content?.TabContainer?.name }}
          </p>
        </ng-container>
        <div
          class="form-group-box"
          *ngIf="contentType !== 'BANNER' && contentType !== 'EVENT'"
        >
          <!-- <h3>Content</h3> -->
          <bh-content-type-select
            [selectedTypeValue]="isUpload ? 'UPLOAD' : content?.type"
            [readOnly]="editMode === 'edit'"
            (selectEvent)="setContentType($event)"
          ></bh-content-type-select>

          <div class="margin-bottom-8" *ngIf="isUpload">
            <a
              [href]="content?.url || content?.icon"
              target="_blank"
              class="no-underline"
              >{{ content?.url || content?.icon }}</a
            >
          </div>

          <bh-input
            [formGroup]="form1"
            type="ion-textarea"
            formControlName="url"
            placeholder="Paste copied content URL"
            label="Content URL"
            position="stacked"
            [maxlength]="1000"
            [value]=""
            [validationMessages]="validationMessages"
            [submitAttempted]="submitAttempted"
            *ngIf="showLinkUrl || contentType === 'UPLOAD'"
          ></bh-input>

          <bh-input
            [formGroup]="form1"
            type="ion-text"
            formControlName="videoId"
            placeholder=""
            [label]="contentType === 'VIDEO' ? 'YouTube Video ID' : 'Tango Tutorial ID'"
            position="stacked"
            [maxlength]="1000"
            [value]=""
            [validationMessages]="validationMessages"
            [submitAttempted]="submitAttempted"
            *ngIf="contentType === 'VIDEO' || contentType === 'TANGO'"
          ></bh-input>

          <!--  && isUpload -->
          <ng-container *ngIf="contentType === 'LINK' && isUpload">
            <bh-file-uploader
              [backgroundImageURL]="uploaderBackgroundImage"
              uploadLabel="Drop file to upload"
              [allowedMimeTypes]="allowedMimeTypes"
            ></bh-file-uploader>
          </ng-container>
        </div>
        <div *ngIf="contentType !== 'UNDEFINED'">
          <div class="form-group-box">
            <h3>Properties</h3>
            <bh-icon-select
              iconStyle="outline"
              [label]="iconSelectLabel"
              [selectedIconName]="content?.icon"
              (selectIconEvent)="setIconSelection($event)"
              *ngIf="contentType === 'BANNER'"
            ></bh-icon-select>
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="name"
              placeholder=""
              label="Name"
              position="stacked"
              [maxlength]="100"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="description"
              placeholder=""
              [label]="contentType === 'EVENT' ? 'Location' : 'Description'"
              position="stacked"
              [maxlength]="1000"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType !== 'BANNER'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-select"
              formControlName="status"
              placeholder=""
              label="Status"
              position="stacked"
              [selectOptions]="statusOptions"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="text"
              placeholder=""
              [label]="contentType === 'EVENT' ? 'Details' : 'Text'"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType === 'BANNER' || contentType === 'EVENT'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="showRedirectUrl"
              placeholder=""
              label="Link to web resource"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType === 'BANNER' || contentType === 'GOLIVE'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="eventUrl"
              placeholder="Paste copied content URL"
              label="Event URL"
              position="stacked"
              [maxlength]="1000"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType === 'EVENT'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-number"
              formControlName="durationMin"
              placeholder=""
              label="Duration (minutes)"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType !== 'BANNER' && contentType !== 'GOLIVE' && contentType !== 'EVENT'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-select"
              formControlName="documentType"
              placeholder=""
              label="Document Type"
              position="stacked"
              [selectOptions]="documentOptions"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType !== 'BANNER' && contentType !== 'GOLIVE' && contentType !== 'EVENT' && contentType !== 'TANGO' && contentType !== 'VIDEO'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="keywords"
              placeholder=""
              label="Keywords"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType !== 'BANNER' && contentType !== 'GOLIVE' && contentType !== 'EVENT' && false"
            ></bh-input>
          </div>
          <div
            class="form-group-box"
            *ngIf="contentType !== 'BANNER' && contentType !== 'EVENT'"
          >
            <h3>Featured</h3>
            <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="featured"
              placeholder=""
              label="Show in Home's Featured Content box"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>
          </div>

          <div class="form-group-box">
            <h3 *ngIf="contentType !== 'EVENT'">Availability</h3>
            <h3 *ngIf="contentType === 'EVENT'">Event Times</h3>
            <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="showEndDateTime"
              placeholder=""
              label="This is time-limited content"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="contentType !== 'EVENT'"
            ></bh-input>

            <div
              class="date-and-time-controls"
              [ngClass]="{'row': contentType === 'EVENT'}"
            >
              <div class="date-time-container">
                <bh-input
                  [formGroup]="form1"
                  type="date"
                  formControlName="startDate"
                  placeholder=""
                  [label]="contentType === 'EVENT' ? 'Event Start Date' : 'Post Date'"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  [label]="contentType === 'EVENT' ? 'Event Start Time (Military time)' : 'Post Time (Military time)'"
                  minLength="5"
                  maxlength="5"
                  formControlName="startTime"
                  placeholder="HH:mm"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
              </div>

              <!-- *ngIf="showEndDateTime" -->
              <div
                class="date-time-container"
                *ngIf="showEndDateTime || contentType === 'EVENT'"
              >
                <bh-input
                  [formGroup]="form1"
                  type="date"
                  formControlName="endDate"
                  placeholder=""
                  [label]="contentType === 'EVENT' ? 'Event End Date' : 'Takedown Date'"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  [label]="contentType === 'EVENT' ? 'Event End Time (Military time)' : 'Takedown Time (Military time)'"
                  minLength="5"
                  maxlength="5"
                  formControlName="endTime"
                  placeholder="HH:mm"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
              </div>
            </div>
          </div>

          <div class="form-group-box" *ngIf="contentType !== 'EVENT'">
            <h3>Content Location</h3>
            <div class="select-row">
              <bh-input
                [formGroup]="form1"
                type="ion-select"
                formControlName="class"
                placeholder=""
                label="Content Class"
                position="stacked"
                [selectOptions]="classOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <a
                href="javascript:void(0)"
                class="tag"
                (click)="addContainer('CLASS')"
                ><ion-icon name="add-outline" class="action-icon"></ion-icon
              ></a>
            </div>
            <div class="select-row" *ngIf="this.form1.controls.class.value">
              <bh-input
                [formGroup]="form1"
                type="ion-select"
                formControlName="category"
                placeholder=""
                label="Category"
                position="stacked"
                [selectOptions]="categoryOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <a
                href="javascript:void(0)"
                class="tag"
                (click)="addContainer('CATEGORY')"
                ><ion-icon name="add-outline" class="action-icon"></ion-icon
              ></a>
            </div>
            <div class="select-row" *ngIf="this.form1.controls.category.value">
              <bh-input
                [formGroup]="form1"
                type="ion-select"
                formControlName="application"
                placeholder=""
                label="Application"
                position="stacked"
                [selectOptions]="applicationOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <a
                href="javascript:void(0)"
                class="tag"
                (click)="addContainer('APP')"
                ><ion-icon name="add-outline" class="action-icon"></ion-icon
              ></a>
            </div>

            <div
              class="select-row"
              *ngIf="this.form1.controls.application.value && this.contentType !== 'BANNER'"
            >
              <bh-input
                [formGroup]="form1"
                type="ion-select"
                formControlName="tab"
                placeholder=""
                label="Tab"
                position="stacked"
                [selectOptions]="tabOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <a
                href="javascript:void(0)"
                class="tag"
                (click)="addContainer('TAB')"
                ><ion-icon name="add-outline" class="action-icon"></ion-icon
              ></a>
            </div>
          </div>

          <ng-container>
            <div class="form-group-box">
              <h3>Tags</h3>
              <div class="tags-listing">
                <ng-container *ngFor="let t of coreTags">
                  <a
                    href="javascript:void(0)"
                    (click)="editTag(t)"
                    class="tag"
                    *ngIf="t.isActive"
                  >
                    {{ t.Tag.name | capitalize }}
                    <a href="javascript:void(0)" (click)="removeTag(t, $event)"
                      ><ion-icon
                        name="remove-circle-outline"
                        class="action-icon end-icon"
                      ></ion-icon
                    ></a>
                  </a>
                </ng-container>
                <a href="javascript:void(0)" class="tag" (click)="addTag('TAG')"
                  ><ion-icon name="add-outline" class="action-icon"></ion-icon
                ></a>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="editMode === 'edit'">
            <div class="form-group-box">
              <h3>Record Information</h3>
              <div class="record-row">
                <div class="record-label">ID</div>
                <div class="record-value">{{ content?.id }}</div>
              </div>
              <div class="record-row">
                <div class="record-label">Parent ID</div>
                <div class="record-value">{{ content?.parentId }}</div>
              </div>
              <div class="record-row">
                <div class="record-label">Type</div>
                <div class="record-value">{{ content?.type }}</div>
              </div>
              <div class="record-row">
                <div class="record-label">URL</div>
                <div class="record-value">
                  {{ content?.url || content?.icon }}
                </div>
              </div>
              <div class="record-row">
                <div class="record-label">Created By</div>
                <div class="record-value">
                  {{ content?.CreatedByUser?.firstName }} {{
                  content?.CreatedByUser?.lastName }} ({{
                  content?.CreatedByUser?.userName }})
                </div>
              </div>
              <div class="record-row">
                <div class="record-label">Created At</div>
                <div class="record-value">
                  {{ content?.createdAt | formatTimestamp }}
                </div>
              </div>
              <div class="record-row">
                <div class="record-label">Updated By</div>
                <div class="record-value">
                  {{ content?.UpdatedByUser?.firstName }} {{
                  content?.UpdatedByUser?.lastName }} ({{
                  content?.UpdatedByUser?.userName }})
                </div>
              </div>
              <div class="record-row">
                <div class="record-label">Updated At</div>
                <div class="record-value">
                  {{ content?.updatedAt | formatTimestamp }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button (click)="save()" [disabled]="!form1.valid"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ion-content>

<ion-footer class="mobile-only" *ngIf="contentType !== 'UNDEFINED'">
  <ion-button (click)="save()" [disabled]="!form1.valid || hasFileUpload()"
    >Save</ion-button
  >
</ion-footer>
