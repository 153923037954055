import { Subscription, firstValueFrom } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ContentContainer } from 'src/app/models/container';
import { ContainerType } from 'src/app/models/container-type';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { SelectOption } from 'src/app/models/_core/select-option';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import * as moment from 'moment';
import { Icon } from 'src/app/models/icon-set';
import { BhFileUploaderComponent } from 'src/app/components/_core/bh-file-uploader/bh-file-uploader.component';
import { ManageTagListPage } from '../manage-tag-list/manage-tag-list.page';
import { ContainerTag } from 'src/app/models/tag';
import { ManageTagEditorPage } from '../manage-tag-editor/manage-tag-editor.page';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';
import { ConstantsService } from 'src/app/services/_core/constants/constants.service';
import { start } from 'repl';



@Component({
  selector: 'app-manage-container-editor',
  templateUrl: './manage-container-editor.page.html',
  styleUrls: ['./manage-container-editor.page.scss'],
})
export class ManageContainerEditorPage implements OnInit {
  @Input() editMode: 'new' | 'edit' = 'new';
  @Input() containerType: ContainerType;
  @Input() containerId: string;
  @Input() parentId: string;
  @ViewChild(BhFileUploaderComponent) fileUploader: BhFileUploaderComponent;
  sectionTags: ContainerTag[] = [];
  coreTags: ContainerTag[] = [];
  modalTitle = '';
  containerTypeName = '';
  container: ContentContainer;
  parentContainer: ContentContainer;
  showEndDateTime = false;
  showRedirectUrl = false;
  showGoLiveOptions = false;
  textFieldLabel = 'Information Box';
  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    description: [null],
    // text: [null],
    startDate: [null, Validators.required],
    startTime: [
      null,
      [
        Validators.required,
        Validators.pattern(this.constants.validatorParams.militaryTimePattern),
      ],
    ],
    showEndDateTime: [null],
    endDate: [null, Validators.required],
    endTime: [
      null,
      [
        Validators.required,
        Validators.pattern(this.constants.validatorParams.militaryTimePattern),
      ],
    ],
    showGoLiveOptions: [false],
    goLiveHeading: [null],
    goLiveStartDate: [null],
    goLiveStartTime: [
      null,
      [Validators.pattern(this.constants.validatorParams.militaryTimePattern)],
    ],
    goLiveEndDate: [null],
    goLiveEndTime: [
      null,
      [Validators.pattern(this.constants.validatorParams.militaryTimePattern)],
    ],
    imageUrl: [null],
    featured: [null],
    featuredHeadline: [null],
    featuredDetail: [null],
    showRedirectUrl: [null],
    layout: [null],
    appUrl: [null],
    url: [null],
    path: [null],
    parentId: [null],
    launchButtonLabel: [null],
    isActive: [true],
  },
  { validators: this.dateRangeValidator() });
  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  nameValidTimer = null;
  checkTimer = null;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  formReady = false;

  validationMessages = {
    name: [{ type: 'required', message: 'Name is required.' }],
    startDate: [{ type: 'required', message: 'Post Date is required.' }],
    startTime: [
      { type: 'required', message: 'Post Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
    endDate: [{ type: 'required', message: 'Takedown Date is required.' }],
    endTime: [
      { type: 'required', message: 'Takedown Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
    // audience: [{ type: 'required', message: 'Audience is required.' }],
    featuredHeadline: [
      { type: 'required', message: 'Featured Headline is required.' },
    ],
    featuredDetail: [
      { type: 'required', message: 'Short Description is required.' },
    ],
    goLiveHeading: [
      { type: 'required', message: 'Go-Live Topic is required.' },
    ],
    goLiveStartTime: [
      { type: 'required', message: 'Go-Live Start Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
    goLiveEndTime: [
      { type: 'required', message: 'Go-Live End Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
  };
  timeOptions: SelectOption[];
  featuredOptions: SelectOption[] = [
    {
      label: 'Not featured',
      value: null,
      active: 1,
    },
    {
      label: 'Home Page',
      value: 'HOME',
      active: 1,
    },
    {
      label: 'Class Page',
      value: 'CLASS',
      active: 1,
    },
  ];

  layoutOptions: SelectOption[] = [
    {
      label: 'Single Column (default/mobile)',
      value: 'SINGLE',
      active: 1,
    },
    {
      label: 'Two Columns',
      value: 'DOUBLE',
      active: 1,
    },
    {
      label: 'Menu Navigation',
      value: 'MENU',
      active: 1,
    },
  ];

  audienceOptions: SelectOption[] = [];
  audiences = {};

  constructor(
    private formBuilder: FormBuilder,
    private containerService: ContainerService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private helpers: HelperUtilitiesService,
    private errorHandler: ErrorHandlerService,
    private constants: ConstantsService
  ) //private appService:
  {}

  ngOnInit() {
    this.audienceOptions = this.containerService.getAudienceOptions();
    this.setEditMode();
    this.setFormFields();
    this.subscribeToControls();
  }

  dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('goLiveStartDate').value;
      const endDate = control.get('goLiveEndDate').value;

      if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
        return { dateRangeInvalid: true };
      }

      return null;
    };
  }

  setEditMode() {
    const typeName = this.containerService.translateContainerTypeCode(
      this.containerType
    );
    this.containerTypeName = typeName;
    if (this.editMode === 'edit' && this.containerId) {
      this.modalTitle = 'Edit ' + typeName;
      this.loadData();
    } else {
      this.modalTitle = 'New ' + typeName;
      this.container = {};
      const defaultStartTimeMoment = moment();
      const defaultEndDateTimeMoment = moment().add(100, 'years');
      this.form1.controls.startDate.setValue(
        defaultStartTimeMoment.format('YYYY-MM-DD')
      );
      this.form1.controls.startTime.setValue('00:00');
      this.form1.controls.endDate.setValue(
        defaultEndDateTimeMoment.format('YYYY-MM-DD')
      );
      this.form1.controls.endTime.setValue('23:59');
      this.form1.controls.layout.setValue('SINGLE');
      this.form1.controls.featured.setValue(false);
    }

    if (this.containerType === 'APP') {
      this.form1.controls.featuredDetail.setValidators([Validators.required]);
      this.form1.controls.featuredDetail.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }



  subscribeToControls() {
    this.subscriptions.push(
      this.form1.controls.showEndDateTime.valueChanges.subscribe((val) => {
        let endMoment = val ? moment() : moment().add(100, 'years');
        this.form1.controls.endDate.setValue(endMoment.format('YYYY-MM-DD'));
        this.form1.controls.endTime.setValue(endMoment.format('HH:mm'));
        this.showEndDateTime = val;
        console.log('val end data time', val);
      }),
      this.form1.controls.showRedirectUrl.valueChanges.subscribe((val) => {
        this.showRedirectUrl = val;
      }),

      this.form1.controls.name.valueChanges.subscribe((val) => {
        if (this.nameValidTimer) {
          clearTimeout(this.nameValidTimer);
          this.nameValidTimer = null;
        }
        if(this.editMode === 'new') {
        this.nameValidTimer = setTimeout(() => {

          this.checkForExistingName(val);
        }, 300);
      }
        console.log('val name', val);
      }),

      this.form1.controls.featured.valueChanges.subscribe((val) => {
        switch (val) {
          case 'CATEGORY':
          case 'APP':
            this.form1.controls.featuredHeadline.setValidators([
              Validators.required,
            ]);
            this.form1.controls.featuredHeadline.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false,
            });
            break;

          default:
            this.form1.controls.featuredHeadline.clearValidators();
            this.form1.controls.featuredHeadline.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false,
            });
            break;
        }
      }),
      this.form1.controls.showGoLiveOptions.valueChanges.subscribe((val) => {
        this.showGoLiveOptions = val;
        if (val) {
          this.form1.controls.goLiveHeading.setValidators([
            Validators.required,
          ]);
          this.form1.controls.goLiveHeading.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        } else {
          this.form1.controls.goLiveHeading.clearValidators();
          this.form1.controls.goLiveHeading.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        }
      }),
    );
  }

  async checkForExistingName(name: string) {
    let res: any;
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (name && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          console.log('container type', this.containerType);
          if (this.containerType === 'APP') {
            res = await firstValueFrom(
              this.containerService.getAll('APP', null, false)
            );
          }
          else if (this.containerType === 'CATEGORY') {
            res = await firstValueFrom(
              this.containerService.getAll('CATEGORY', null, false)
            );
          } else if(this.containerType === 'TAB') {
            res = await firstValueFrom(
              this.containerService.getAll('TAB', null, false)
            );
          }

          const containerOfType =
            res && res.length > 0
              ? res.filter((r) => r.type === this.containerType)
              : [];

              console.log('container of Type', containerOfType);

            if(containerOfType.length > 0) {
              const nameExists = res.filter((r) => r.name.toLowerCase() === name.toLowerCase());
              console.log('name exists', nameExists);

              if (nameExists.length > 0) {
                this.checkStatus = 'exists';
                this.formReady = false;
              } else {
                this.checkStatus = 'new';
                this.formReady = true;
              }
            }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.errorHandler.handleError(err, 'manage-tag-editor.loadData');
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }
  }

  checkString(input: string): boolean {
    const regexPattern = /^[A-Za-z]{2}\d{2}.{3,}$/;
    return regexPattern.test(input);
  }

  setFormFields() {
    this.timeOptions = this.helpers.getTimeOptions();
    // if (this.containerType === 'APP') {
    //   this.form1.controls.audience.setValidators([Validators.required]);
    //   this.form1.controls.audience.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    // }
  }

  async loadData() {
    try {
      const res = await firstValueFrom(
        this.containerService.getById(this.containerId)
      );
      if (res) {
        this.container = res;

        console.log('Loading container', this.container);
        this.form1.controls.name.setValue(res.name);
        this.form1.controls.description.setValue(res.description);
        this.form1.controls.isActive.setValue(res.isActive);
        this.form1.controls.layout.setValue(res.layout);
        this.form1.controls.appUrl.setValue(res.appUrl);
        this.form1.controls.featured.setValue(
          res.featured === 'HOME' ? true : false
        );
        this.form1.controls.featuredHeadline.setValue(res.featuredHeadline);
        this.form1.controls.featuredDetail.setValue(res.featuredDetail);

        // Load Start DateTime
        const startDateTimeMoment = this.helpers.convertUtcToLocal(
          moment(res.startDateTime),
          undefined
        );
        this.form1.controls.startDate.setValue(
          startDateTimeMoment.format('YYYY-MM-DD')
        );
        this.form1.controls.startTime.setValue(
          startDateTimeMoment.format('HH:mm')
        );

        // Load End DateTime
        const endDateTimeMoment = this.helpers.convertUtcToLocal(
          moment(res.endDateTime),
          undefined
        );
        this.form1.controls.endDate.setValue(
          endDateTimeMoment.format('YYYY-MM-DD')
        );
        this.form1.controls.endTime.setValue(endDateTimeMoment.format('HH:mm'));

        // Set Show EndDateTime toggle
        const nowMoment = moment();
        this.showEndDateTime = endDateTimeMoment.diff(nowMoment, 'years') <= 10;

        this.form1.controls.showRedirectUrl.setValue(this.showRedirectUrl);
        this.form1.controls.url.setValue(res.url);
        this.form1.controls.launchButtonLabel.setValue(res.launchButtonLabel);
        this.form1.controls.showGoLiveOptions.setValue(
          res.goLiveHeading && res.show ? true : false
        );
        this.form1.controls.goLiveHeading.setValue(res.goLiveHeading);

        // Set Go-Live Start DateTime
        if (res.goLiveStartDateTime) {
          const goLiveStartMoment = this.helpers.convertUtcToLocal(
            moment(res.goLiveStartDateTime),
            undefined
          );
          // console.log(
          //   'loadData(): goLiveStartDateTime',
          //   goLiveStartMoment,
          //   goLiveStartMoment.format('MM/DD/YYYY HH:mm')
          // );
          this.form1.controls.goLiveStartDate.setValue(
            goLiveStartMoment.format('YYYY-MM-DD')
          );
          this.form1.controls.goLiveStartTime.setValue(
            goLiveStartMoment.format('HH:mm')
          );
        }

        // Set Go-Live End DateTime
        if (res.goLiveEndDateTime) {
          const goLiveEndMoment = this.helpers.convertUtcToLocal(
            moment(res.goLiveEndDateTime),
            undefined
          );
          console.log(
            'loadData(): goLiveEndDateTime',
            goLiveEndMoment,
            goLiveEndMoment.format('MM/DD/YYYY HH:mm')
          );
          this.form1.controls.goLiveEndDate.setValue(
            goLiveEndMoment.format('YYYY-MM-DD')
          );
          this.form1.controls.goLiveEndTime.setValue(
            goLiveEndMoment.format('HH:mm')
          );
        }

        // Set Tags
        this.sectionTags = this.container.ContainerTags.filter(
          (c) => c.Tag.type === 'SECTION' && c.isActive
        );
        this.coreTags = this.container.ContainerTags.filter(
          (c) => c.Tag.type === 'TAG' && c.isActive
        );

        const parentRes = await firstValueFrom(
          this.containerService.getById(this.containerId)
        );
        if (parentRes) {
          this.parentContainer = parentRes;
        }

        // Load Audiences
        if (res.audience) {
          const auds = res.audience.split(', ');
          for (const a of auds) {
            if (a) {
              this.audiences[a] = true;
            }
          }
        }
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.errorHandler.handleError(err, 'manage-container-editor.loadData');
    }
  }

  setIconSelection(icon: Icon) {
    this.container.imageUrl = icon.name;
  }

  setAudiences() {
    console.log('setAudiences', this.audiences);
  }

  validateAudience() {
    if (this.containerType === 'APP') {
      for (const key in this.audiences) {
        if (this.audiences.hasOwnProperty(key)) {
          const value = this.audiences[key];
          if (value) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  async save() {
    // console.log('saved: ', this.form1);
    const validAudience = this.validateAudience();
    if (!validAudience) {
      await this.presentAudienceAlert();
      return;
    }
    this.submitAttempted = true;
    if (this.form1.valid) {
      this.notifications.startLoading('Saving your work');
      try {
        // Update container properties
        this.container.type = this.containerType;
        this.container.name = this.form1.controls.name.value;
        this.container.description = this.form1.controls.description.value;
        // this.container.text = this.form1.controls.text.value;

        // Parse Date Format for UTC conversion
        const parseDateFormat = 'YYYY-MM-DD HH:mm';

        // Update Start Date/Time
        const startDate = moment(this.form1.controls.startDate.value).format('YYYY-MM-DD');
        const startTime = this.form1.controls.startTime.value;
        console.log('startDate', startDate, 'startTime', startTime);
        const startDateTime = startDate + ' ' + startTime;
        const startMoment = moment(startDateTime, parseDateFormat);
        console.log('startDateTime', startDateTime, 'startMoment', startMoment);

        const startMomentUtc = this.helpers.convertLocalToUtc(
          startMoment.format(parseDateFormat),
          parseDateFormat
        );
        this.container.startDateTime = startMomentUtc.toISOString(true);

        // Update End Date/Time
        const endDate = moment(this.form1.controls.endDate.value).format('YYYY-MM-DD');
        const endTime = this.form1.controls.endTime.value;
        const endDateTime = endDate + ' ' + endTime;
        const endMoment = moment(endDateTime, parseDateFormat);
        const endMomentUtc = this.helpers.convertLocalToUtc(
          endMoment.format(parseDateFormat),
          parseDateFormat
        );
        this.container.endDateTime = endMomentUtc.toISOString(true);

        this.container.featured = this.form1.controls.featured.value
          ? 'HOME'
          : null;
        this.container.featuredHeadline =
          this.form1.controls.featuredHeadline.value;
        this.container.featuredDetail =
          this.form1.controls.featuredDetail.value;
        if (this.container.featured && !this.container.featuredSort) {
          this.container.featuredSort = 0;
        }
        this.container.url = this.form1.controls.url.value
          ? this.form1.controls.url.value
          : null;
        this.container.path = this.form1.controls.path.value;
        this.container.parentId = this.parentId;
        this.container.isActive = this.form1.controls.isActive.value;
        const appUrl = this.form1.controls.appUrl.value;
        this.container.appUrl = appUrl ? appUrl : null;
        this.container.launchButtonLabel =
          this.form1.controls.launchButtonLabel.value;
        if (!this.showGoLiveOptions) {
          this.form1.controls.goLiveHeading.setValue(null);
        }

        if(this.showGoLiveOptions) {
            this.container.show = true;
        }

        this.container.goLiveHeading = this.form1.controls.goLiveHeading.value;

        // Update Go-Live Start DateTime
        const goLiveStartDate = moment(this.form1.controls.goLiveStartDate.value).format('YYYY-MM-DD');
        const goLiveStartTime = this.form1.controls.goLiveStartTime.value;
        // console.log(
        //   'save(): goLiveStartDate: ' + goLiveStartDate,
        //   goLiveStartTime
        // );

        if (goLiveStartDate && goLiveStartTime) {
          const goLiveStartDateTime =
            moment(goLiveStartDate).format('YYYY-MM-DD') +
            ' ' +
            goLiveStartTime;
          const goLiveStartMoment = moment(
            goLiveStartDateTime,
            parseDateFormat
          );
          const goLiveStartMomentUtc = this.helpers.convertLocalToUtc(
            goLiveStartMoment.format(parseDateFormat),
            parseDateFormat
          );
          this.container.goLiveStartDateTime =
            goLiveStartMomentUtc.toISOString(true);
        }

        // Update Go-Live End DateTime
        const goLiveEndDate = moment(this.form1.controls.goLiveEndDate.value).format('YYYY-MM-DD');
        const goLiveEndTime = this.form1.controls.goLiveEndTime.value;
        // console.log(
        //   'save(): goLiveEndDate: ' + goLiveEndDate,
        //   goLiveEndTime
        // );

        if (goLiveEndDate && goLiveEndTime) {
          // const goLiveEndDateTimeMoment = moment(goLiveEnd + 'T00:00:00', 'YYYY-MM-DDTHH:mm:ss');
          const goLiveEndDateTime =
            moment(goLiveEndDate).format('YYYY-MM-DD') + ' ' + goLiveEndTime;
          // console.log(
          //   'save(): goLiveEndDate: ' + goLiveEndDate,
          //   goLiveEndTime,
          //   goLiveEndDateTime
          // );
          const goLiveEndMoment = moment(goLiveEndDateTime, parseDateFormat);
          const goLiveEndMomentUtc = this.helpers.convertLocalToUtc(
            goLiveEndMoment.format(parseDateFormat),
            parseDateFormat
          );
          this.container.goLiveEndDateTime =
            goLiveEndMomentUtc.toISOString(true);
        }

        this.container.layout = this.form1.controls.layout.value;
        const files = this.fileUploader ? this.fileUploader.getFiles() : null;
        // console.log('Container before saving', this.container);

        // Save audiences
        const auds: string[] = [];
        for (const a of this.audienceOptions) {
          const aVal = this.audiences[a.value];
          if (aVal) {
            auds.push(a.value);
          }
        }

        this.container.audience = auds.join(', ');
        // const auds = audience.split(',');
        // console.log('saveAuds', auds, this.container.audience);

        //console.log('Saving', this.container);

        // Save container
        let res;
        if (this.editMode === 'edit' && this.containerId) {
          res = await firstValueFrom(
            this.containerService.update(this.container)
          );
          if(this.showGoLiveOptions && this.container.show) {
            const goLives = this.containerService.getGoLiveSubjects();
            console.log('goLives', goLives);

            this.containerService.setGoLiveSubjects([...goLives, this.container]);
            console.log('this.containerService.getGoLiveSubjects()', this.containerService.getGoLiveSubjects());
          }
        } else {
          res = await firstValueFrom(
            this.containerService.create(this.container)
          );
          this.container.id = res.id;
          if(this.showGoLiveOptions && this.container.show) {
            const goLives = this.containerService.getGoLiveSubjects();
            console.log('goLives', goLives);

            this.containerService.setGoLiveSubjects([...goLives, this.container]);
            console.log('this.containerService.getGoLiveSubjects()', this.containerService.getGoLiveSubjects());

          }
        }

        //Check for file upload
        if (files && files.length === 1) {
          this.container.imageUrl = files[0].name;
          const rawFile = files[0].rawFile;
          const res = await firstValueFrom(
            this.containerService.uploadFile(this.container, rawFile)
          );
        }

        this.analytics.clickEvent('manage-container-editor: save', '');
        this.notifications.stopLoading();
        this.notifications.showToast('Saved successfully.');
        this.modalCtrl.dismiss({ isSaved: true, newId: res.id });
      } catch (err) {
        this.notifications.stopLoading();
        this.errorHandler.handleError(err, 'manage-container-editor.save');
      }
    } else {
      console.warn('invalid form', this.form1);
    }
  }


  presentAudienceAlert() {
    this.notifications.showAlert(
      'Audience Not Selected',
      'At least one audience selection is required.',
      'danger'
    );
  }

  async addTag(tagType: 'SECTION' | 'TAG') {
    this.analytics.clickEvent('manage-container-editor > Add tag/section', '');
    // console.log('Tag');
    const modal = await this.modalCtrl.create({
      component: ManageTagListPage,
      componentProps: {
        // editMode: 'new',
        tagType,
        // classContainer: this.classContainer,
        // categoryContainer: this.categoryContainer,
        // appContainer: this.appContainer,
        // tabContainer: this.tabContainer,
        parentContainerId: this.parentContainer.id,
      },
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data && data.data.isSaved) {
        if (data.data.tag) {
          const newContainerTag = {
            Tag: data.data.tag,
            containerId: this.container.id,
            isActive: true,
            tagId: null,
          };
          if (this.container && !this.container.ContainerTags) {
            this.container.ContainerTags = [];
          }
          this.container.ContainerTags.push(newContainerTag);
          this.sectionTags = this.container.ContainerTags;
        }
      }
    });

    await modal.present();
  }

  async editTag(containerTag: ContainerTag) {
    this.analytics.clickEvent(
      'list-card > Edit section',
      containerTag.Tag.name
    );
    const modal = await this.modalCtrl.create({
      component: ManageTagEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        tagType: containerTag.Tag.type,
        tagId: containerTag.Tag.id,
        // classContainer: this.classContainer,
        // categoryContainer: this.categoryContainer,
        // appContainer: this.appContainer,
        // tabContainer: this.tabContainer,
        parentContainerId: this.parentContainer.id,
        activeSegment: 'form',
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.isSaved) {
        this.loadData();
      }
    });
    await modal.present();
  }

  async removeTag(containerTag: ContainerTag, ev) {
    ev.stopPropagation();
    for (const t of this.container.ContainerTags) {
      if (t.Tag.name === containerTag.Tag.name) {
        t.isActive = false;
        // console.log('match detected:', t.Tag.name, containerTag.Tag.name);
      }
    }
    this.sectionTags = this.container.ContainerTags;
  }

  dismiss() {
    this.analytics.clickEvent('manage-container-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }
}
