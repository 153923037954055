import { Keyboard } from '@capacitor/keyboard';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from './services/_core/notifications/notifications.service';
import { UserDeviceService } from './services/_core/user-device/user-device.service';
import { VerlockerService } from './services/_core/verlocker/verlocker.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from './services/_core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ThemeOption } from './models/_core/theme-option';
import { StatusBar, Style } from '@capacitor/status-bar';
import { User } from './models/user';
import { ManageUserListPage } from './pages/manage-user-list/manage-user-list.page';
import { ManageContainerListPage } from './pages/manage-container-list/manage-container-list.page';
import { ManageTagListPage } from './pages/manage-tag-list/manage-tag-list.page';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { CapacitorJailbreakRootDetection, JailbreakRootResult } from '@evehr/capacitor-jailbreak-root-detection';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
/**
 * ID: app-component
 * Name: App-Component
 * Description: Root App Component that handles root-level app-wide logic.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-26 - MW - v2: Integraeted dark mode preference
 */
export class AppComponent implements OnInit {
  env = environment;
  prefersDark = false;
  theme: ThemeOption = 'M';
  subs: Subscription[] = [];
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;
  loadingMessage = '';
  isMenuOpen = false;
  presentationMode = false;
  isJailBroken = false;
  authUser: User;

  constructor(
    private deviceService: UserDeviceService,
    private platform: Platform,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private authService: AuthService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private zone: NgZone,
    private router: Router
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceService.loadDeviceProperties();
  }

  async ngOnInit() {
    this.listenForThemePreference();
    this.subscribeToMenu();
    this.subscribeToUserDevice();
    this.subscribeToUserState();
    this.getPresentationMode();
    this.checkForJailbreakOrRootedDevice();
  }


  listenForThemePreference() {
    this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.setTheme();
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (mediaQuery) => {
        // console.log('listening for theme pref', mediaQuery);
        const device = this.deviceService.deviceSubject.getValue();
        device.prefersDark = mediaQuery.matches;
        this.prefersDark = device.prefersDark;
        this.deviceService.deviceSubject.next(device);
        this.setTheme();
      });
  }

  subscribeToMenu() {
    this.subs.push(
      this.authService.menuOpen.subscribe(val => {
        this.isMenuOpen = val;
        if (this.isMenuOpen) {
          this.menuCtrl.open();
        } else {
          this.menuCtrl.close();
        }
      })
    );
  }

  subscribeToUserState() {
    this.subs.push(
      this.authService.userStateSubject.subscribe(us => {
        // console.log('UserState updated', us);
        if (us && us.theme) {
          this.theme = us.theme;
          this.setTheme();
        }
      })
    );
  }

  subscribeToUserDevice() {
    this.subs.push(
      this.deviceService.deviceSubject.subscribe(d => {
        // console.log('UserDevice updated', d);
        this.prefersDark = d.prefersDark;
        this.setTheme();
      })
    );
  }

  dismissMenu() {
    this.authService.toggleMenu();
  }

  togglePresentationMode(ev) {
    ev.stopPropagation();
    this.presentationMode = !this.presentationMode;
    this.setPresentationMode(this.presentationMode);
  }

  setPresentationMode(event) {
    // console.log('event set presentation', event);
    this.authService.setPresentationState(event);
  }

  getPresentationMode() {
    this.presentationMode = this.authUser ? this.authUser.presentationMode : false;
  }

  setTheme() {
    let currentTheme: ThemeOption;
    // Check if theme is user-defined
    if (this.env.theme === 'user') {
      // Check if user is not matching OS
      if (this.theme !== undefined && this.theme !== 'M') {
        console.log('found theme', this.theme);
        switch (this.theme) {
          case 'D':
            // Set to dark
            document.body.classList.add('dark');
            currentTheme = 'D';
            break;

          default:
            // Set to light
            document.body.classList.remove('dark');
            currentTheme = 'L';
            break;
        }
      } else if (this.prefersDark) {
        // console.log('Setting dark theme');
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        // console.log('Setting light theme');
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    } else {
      // Theme is defined by environment
      if (this.env.theme === 'dark') {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    }
    // console.log('Setting theme', this.env.theme, this.theme, this.prefersDark);
    this.authService.setTheme(currentTheme);
  }

  async initializeApp() {
    // await this.storage.create();
    this.platform.ready().then(() => {
      this.analytics.initAnalytics();
      this.initNativeFeatures();
    });
  }

  async initNativeFeatures() {
    if (this.platform.is('mobile')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      // this.statusBar.styleDefault();
      await StatusBar.hide();
      await this.initUniversalLinks();
    }
  }

  async initUniversalLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // console.log('initUniversalLinks', event);
        const splitPoint = this.env.webUrl;
        const route = event.url.split(splitPoint).pop();
        if (route) {
          this.router.navigateByUrl(route);
        }
      });
    });
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val.isLoading;
      this.loadingMessage = val.message;
    });
  }

  checkVersion() {
    if (!this.updateInterval) {
      const checkVersion = firstValueFrom(this.verlockerService.checkVersion());
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          firstValueFrom(this.verlockerService.checkVersion());
        }
      }, 120000);
    }
  }

  async checkForJailbreakOrRootedDevice() {
    const jailRes: JailbreakRootResult = await CapacitorJailbreakRootDetection.isJailbrokenOrRooted();
    // jailRes.result = true; // Testing
    if (jailRes.result) {
      console.log("Device is jailbroken or rooted.", jailRes);
      this.isJailBroken = true;
      document.body.innerHTML = `
        <div class="text-align-center padding-48">
          <h3>Jailbroken or Rooted Device Detected</h3>
          <p>This device is not allowed to run this app.</p>
        </div>
      `;
    } else {
      console.log("Device is not jailbroken or rooted.", jailRes);
      this.isJailBroken = false;
    }

  }

  async manageUsers() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: ManageUserListPage,
      componentProps: {
      }
    });

    modal.onDidDismiss().then((data) => {
    });

    await modal.present();
  }

  async manageTags() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: ManageTagListPage,
      componentProps: {
        tagType: 'TAG',
        selectMode: 'edit'
      }
    });

    modal.onDidDismiss().then((data) => {
    });

    await modal.present();
  }

  async manageContainers() {
    this.menuCtrl.close();
    const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (topModal) {
      topModal.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: ManageContainerListPage,
      cssClass: 'wide-modal',
      componentProps: {}
    });
    await modal.present();
  }

  openEditorGuide() {
    const guideUrl = '/assets/docs/worktips-editors-guide-v1.pdf';
    window.open(guideUrl, '_blank');
  }

  closeMenu() {
    this.menuCtrl.close();
  }

}
