<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon
          name="reorder-two-outline"
          *ngIf="containerType === 'TAB'"
        ></ion-icon>
        <ion-icon
          name="albums-outline"
          *ngIf="containerType === 'CLASS'"
        ></ion-icon>
        <ion-icon
          name="apps-outline"
          *ngIf="containerType === 'CATEGORY'"
        ></ion-icon>
        <ion-icon
          name="browsers-outline"
          *ngIf="containerType === 'APP'"
        ></ion-icon>
        <ion-icon
          name="rocket-outline"
          *ngIf="containerType === 'GOLIVE'"
        ></ion-icon>

        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this container.</p>
    </div>
    <div class="form-panel">
      <div class="form-panel-inner">
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>

        <form [formGroup]="form1">
          <bh-icon-select
            iconStyle="outline"
            label="Mobile App Icon"
            [selectedIconName]="container?.imageUrl"
            (selectIconEvent)="setIconSelection($event)"
            *ngIf="containerType === 'CLASS'"
          ></bh-icon-select>
          <ng-container
            *ngIf="containerType === 'APP' || containerType === 'GOLIVE'"
          >
            <h3 class="text-transform-capitalize margin-top-0">Thumbnail</h3>
            <bh-file-uploader
              [backgroundImageURL]="container?.imageUrl"
            ></bh-file-uploader>
          </ng-container>

          <div class="form-group-box">
            <h3>Properties</h3>
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="name"
              placeholder=""
              label="Name"
              position="stacked"
              [maxlength]="100"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <ng-container
            *ngIf="form1.controls.name.value"
          >
            <div class="check-status" *ngIf="checkStatus === 'checking'">
              <ion-spinner name="crescent" size="small"></ion-spinner>
              Checking for existing {{ containerType.toLowerCase() }}
            </div>

            <div class="check-status success" *ngIf="checkStatus === 'new'">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
              This {{ containerType.toLowerCase() }} name is unique and can be used.
            </div>

            <div
              class="check-status warning"
              *ngIf="checkStatus === 'exists'"
            >
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span
                >This {{ containerType.toLowerCase() }} name is in use and cannot be
                recreated. Use the existing one instead.</span
              >
            </div>
          </ng-container>

            <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="isActive"
              placeholder=""
              label="Active"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="description"
              placeholder=""
              label="Full Description"
              position="stacked"
              [value]=""
              [maxlength]="1000"
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="featuredDetail"
              placeholder=""
              label="Short Description (for App Card)"
              position="stacked"
              [maxlength]="200"
              [required]="true"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="containerType === 'APP'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-select"
              formControlName="layout"
              placeholder=""
              label="Tab Content Layout"
              position="stacked"
              [selectOptions]="layoutOptions"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="containerType === 'TAB'"
            ></bh-input>

            <!-- Hiding URL field per Kara's request - 2/26/24 -->
            <!-- <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="showRedirectUrl"
              placeholder=""
              label="Link to web resource"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="containerType === 'APP'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="url"
              placeholder="https://..."
              label="Paste copied destination URL here"
              position="stacked"
              [maxlength]="1000"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="showRedirectUrl"
            ></bh-input> -->
          </div>

          <ng-container *ngIf="containerType === 'APP'">
            <div class="form-group-box">
              <h3>Audiences</h3>
              <p>Add a check to all that apply.</p>
              <ion-item *ngFor="let a of audienceOptions">
                <ion-checkbox
                  slot="start"
                  [(ngModel)]="audiences[a.value]"
                  (ngModelChange)="setAudiences()"
                  [ngModelOptions]="{standalone: true}"
                ></ion-checkbox>
                <ion-label>{{ a.label }}</ion-label>
              </ion-item>
            </div>
          </ng-container>

          <div class="form-group-box">
            <h3>Availability</h3>
            <bh-input
              [formGroup]="form1"
              type="ion-checkbox"
              formControlName="showEndDateTime"
              placeholder=""
              label="This is time-limited content"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <div class="date-and-time-controls">
              <div class="date-time-container">
                <bh-input
                  [formGroup]="form1"
                  type="date"
                  formControlName="startDate"
                  placeholder=""
                  label="Post Date"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  label="Post Time (Military time)"
                  minLength="5"
                  maxlength="5"
                  formControlName="startTime"
                  placeholder="HH:mm"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
              </div>

              <!-- *ngIf="showEndDateTime" -->
              <div class="date-time-container" *ngIf="showEndDateTime">
                <bh-input
                  [formGroup]="form1"
                  type="date"
                  formControlName="endDate"
                  placeholder=""
                  label="Takedown Date"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  label="Takedown Time (Military time)"
                  minLength="5"
                  maxlength="5"
                  formControlName="endTime"
                  placeholder="HH:mm"
                  position="stacked"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
              </div>
            </div>
          </div>
          <ng-container *ngIf="containerType === 'APP'">
            <div class="form-group-box">
              <h3>Go-Live</h3>
              <bh-input
                [formGroup]="form1"
                type="ion-checkbox"
                formControlName="showGoLiveOptions"
                placeholder=""
                label="This app is undergoing a go-live"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <ng-container *ngIf="showGoLiveOptions">
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  formControlName="goLiveHeading"
                  placeholder="Topic displays in the Go-Live Listing on Home"
                  label="Go-Live Topic"
                  position="stacked"
                  [maxlength]="200"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>

                <div class="date-and-time-controls">
                  <!-- <bh-input
                    [formGroup]="form1"
                    type="date"
                    formControlName="goLiveStartDateTime"
                    placeholder=""
                    label="Go-Live Start Date"
                    position="stacked"
                    [value]=""
                    [validationMessages]="validationMessages"
                    [submitAttempted]="submitAttempted"
                  ></bh-input> -->

                  <div class="date-time-container">
                    <bh-input
                      [formGroup]="form1"
                      type="date"
                      formControlName="goLiveStartDate"
                      placeholder=""
                      label="Go-Live Start Date"
                      position="stacked"
                      [value]=""
                      [validationMessages]="validationMessages"
                      [submitAttempted]="submitAttempted"
                    ></bh-input>
                    <bh-input
                      [formGroup]="form1"
                      type="ion-text"
                      label="Go-Live Start Time (Military time)"
                      minLength="5"
                      maxlength="5"
                      formControlName="goLiveStartTime"
                      placeholder="HH:mm"
                      position="stacked"
                      [value]=""
                      [validationMessages]="validationMessages"
                      [submitAttempted]="submitAttempted"
                    ></bh-input>
                  </div>

                  <!-- <bh-input
                    [formGroup]="form1"
                    type="date"
                    formControlName="goLiveEndDateTime"
                    placeholder=""
                    label="Go-Live End Date"
                    position="stacked"
                    [value]=""
                    [validationMessages]="validationMessages"
                    [submitAttempted]="submitAttempted"
                  ></bh-input> -->

                  <div class="date-time-container">
                    <bh-input
                      [formGroup]="form1"
                      type="date"
                      formControlName="goLiveEndDate"
                      placeholder=""
                      label="Go-Live End Date"
                      position="stacked"
                      [value]=""
                      [validationMessages]="validationMessages"
                      [submitAttempted]="submitAttempted"
                    ></bh-input>
                    <bh-input
                      [formGroup]="form1"
                      type="ion-text"
                      label="Go-Live End Time (Military time)"
                      minLength="5"
                      maxlength="5"
                      formControlName="goLiveEndTime"
                      placeholder="HH:mm"
                      position="stacked"
                      [value]=""
                      [validationMessages]="validationMessages"
                      [submitAttempted]="submitAttempted"
                    ></bh-input>
                  </div>
                    <div class="error-date-time">
                      <div *ngIf="form1.errors?.dateRangeInvalid">
                        End date cannot be before start date.
                      </div>
                    </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="form-group-box" *ngIf="containerType === 'APP'">
            <h3>Launch App Button</h3>
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="launchButtonLabel"
              placeholder="Launch App"
              label="Launch App Button Label"
              position="stacked"
              [maxlength]="50"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="containerType === 'APP'"
            ></bh-input>

            <bh-input
              [formGroup]="form1"
              type="ion-textarea"
              formControlName="appUrl"
              placeholder="http://"
              label="Application URL (if applicable)"
              position="stacked"
              [maxlength]="1000"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
              *ngIf="containerType === 'APP'"
            ></bh-input>
          </div>
          <ng-container *ngIf="containerType === 'APP'">
            <div class="form-group-box">
              <h3>Featured</h3>
              <bh-input
                [formGroup]="form1"
                type="ion-checkbox"
                formControlName="featured"
                placeholder=""
                label="Show as Featured Item on Home"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
                *ngIf="containerType === 'APP'"
              ></bh-input>

              <ng-container *ngIf="this.form1.controls.featured.value">
                <bh-input
                  [formGroup]="form1"
                  type="ion-text"
                  formControlName="featuredHeadline"
                  placeholder=""
                  label="Featured Headline"
                  position="stacked"
                  [maxlength]="100"
                  [required]="this.form1.controls.featured.value"
                  [value]=""
                  [validationMessages]="validationMessages"
                  [submitAttempted]="submitAttempted"
                ></bh-input>
                <div>
                  (Short description is displayed below the "Featured Headline")
                </div>
              </ng-container>
            </div>
          </ng-container>
        </form>

        <div class="record-section" *ngIf="editMode === 'edit'">
          <div class="form-group-box">
            <h3>Record Information</h3>
            <div class="record-row">
              <div class="record-label">ID</div>
              <div class="record-value">{{ container?.id }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Parent ID</div>
              <div class="record-value">{{ container?.parentId }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Type</div>
              <div class="record-value">{{ container?.type }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Sort Seq</div>
              <div class="record-value">{{ container?.sort }}</div>
            </div>
            <div
              class="record-row"
              *ngIf="container?.type === 'CATEGORY' || container?.type === 'APP'"
            >
              <div class="record-label">Image</div>
              <div class="record-value">
                <a [href]="container?.imageUrl" target="_blank"
                  >{{ container?.imageUrl }}</a
                >
              </div>
            </div>
            <div class="record-row" *ngIf="container?.type === 'APP'">
              <div class="record-label">URL</div>
              <div class="record-value">
                <a [href]="container?.url" target="_blank"
                  >{{ container?.url }}</a
                >
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Created By</div>
              <div class="record-value">
                {{ container?.CreatedByUser?.lastName }}, {{
                container?.CreatedByUser?.firstName }} ({{
                container?.CreatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Created At</div>
              <div class="record-value">
                {{ container?.createdAt | formatTimestamp }}
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Updated By</div>
              <div class="record-value">
                {{ container?.UpdatedByUser?.lastName }}, {{
                container?.UpdatedByUser?.firstName }} ({{
                container?.UpdatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Updated At</div>
              <div class="record-value">
                {{ container?.updatedAt | formatTimestamp }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button (click)="save()" [disabled]="editMode === 'new' && checkStatus === 'exists' || !form1.valid">Save</ion-button>
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only">
  <ion-button (click)="save()">Save</ion-button>
</ion-footer>
